<template>
  <div class="account-admin dashboard-card dashboard-card--small order-1">
    <div class="account-admin__title">
      Account overview

      <dashboard-card-tooltip offset="0, 5px">
        {{
          getTooltipTextBySlug('dashboard_account_overview--cg-user-with-balance')
            .replace('${X}', `$${spendLimit}`)
        }}
      </dashboard-card-tooltip>
    </div>

    <v-divider />

    <template v-if="!loading">
      <div class="account-admin__chart-title">
        Personal spend limit
      </div>

      <div class="account-admin__chart">
        <v-progress-circular
          :value="chartValue"
          :size="138"
          :width="51"
          color="#38A39C"
        />

        <div class="chart-info">
          <div class="chart-info__item" v-if="limitType">
            <div class="item__time">{{ limitType }} spend limit</div>
          </div>

          <div class="chart-info__item">
            <div class="item__dot item__dot--limit" />
            <div class="item__name">Spend limit:</div>
            <div class="item__amount">${{ spendLimit }}</div>
          </div>

          <div class="chart-info__item">
            <div class="item__dot" />
            <div class="item__name">Spent:</div>
            <div class="item__amount">${{ (spent > spendLimit ? spendLimit : spent) | priceFilter }}</div>
          </div>
        </div>
      </div>

      <div class="account-admin__approver" v-if="approver">
        <span>Personal approver:</span>

        {{ approver }}
      </div>
    </template>

    <common-loader v-else />
  </div>
</template>

<script>
import Api from '@/axios/api'
import getTooltipTextBySlug from '@/mixins/getTooltipTextBySlug'

import priceFilter from '@corporategift/design-system/filters/priceFilter';

import DashboardCardTooltip from './common/DashboardCardTooltip.vue'

export default {
  name: 'TheAccountAdmin',
  filters: { priceFilter },
  mixins: [getTooltipTextBySlug],
  components: { DashboardCardTooltip },
  data: () => ({
    accountType: null,
    loading: false,
    limitType: null,
    spendLimit: 0,
    spent: 0,
    approver: null
  }),
  computed: {
    chartValue () {
      const { spendLimit, spent } = this

      // return ((spendLimit - spent) / spendLimit) * 100
      return (spent / spendLimit) * 100
    },
  },
  created () {
    this.loading = true

    Api.get('/customer/dashboard/account-overview')
      .then(({ data }) => {
        this.accountType = data.type
        this.spendLimit = data?.limit || 0
        this.spent = data?.spent || 0
        this.limitType = data?.limit_type || null
        this.approver = data?.approver_name ?? null
        if (!data.spent && !data.limit) this.$emit('showAccountInfo', true)
      })
      .finally(() => (this.loading = false))
  }
}
</script>

<style lang="scss" scoped>
@import "@/css/dashboard-card.scss";

.account-admin {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > hr {
    border-color: #D3D2D2;
  }

  &__title {
    font-family: 'Lato-Bold', sans-serif;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
    align-items: center;
    color: #95979D;
    text-transform: uppercase;
  }

  &__chart-title {
    color: #000;
    font-family: 'Lato-Bold', sans-serif;
    font-size: 15px;
    line-height: 40px;
  }

  &__chart {
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: space-between;

    &::v-deep > .v-progress-circular > svg > .v-progress-circular__underlay {
      stroke: #D2F5F3;
    }

    & .chart-info {
      display: flex;
      flex-direction: column;
      flex: 1 1 154px;

      &__item {
        align-items: center;
        display: flex;
        gap: 12px;

        & > .item {
          &__dot {
            height: 13px;
            width: 13px;
            min-width: 13px;
            border-radius: 50%;
            background-color: #38A39C;

            &--limit {
              background-color: #EDFCFB;
            }
          }

          &__name {
            font-family: 'Lato-Bold', sans-serif;
            font-size: 15px;
            line-height: 27px;
            white-space: nowrap;
            color: #62646A;
          }

          &__amount {
            font-family: 'Lato-Regular', sans-serif;
            font-size: 15px;
            line-height: 27px;
            margin-left: auto;
            color: #62646A;
          }

          &__time {
            font-family: 'Lato-Italic', sans-serif;
            font-size: 15px;
            line-height: 27px;
            color: #62646A;
            margin-bottom: 12px;

            &::first-letter {
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }

  &__approver {
    font-size: 15px;
    line-height: 40px;
    font-family: 'Lato-Regular', sans-serif;
    color: #62646A;

    & > span {
      font-size: inherit;
      line-height: inherit;
      font-family: 'Lato-Bold', sans-serif;
      color: inherit;
    }
  }
}
</style>
